@import url('https://fonts.googleapis.com/css2?family=Mitr&family=Saira&display=swap');

*, html {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-family: 'Saira', sans-serif;
}

h1, h2, h3, h4 {
  font-family: 'Saira', sans-serif;
}


html {
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  font-size: 0.8rem;
}

.App {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  align-content: stretch;
  height: 100vh;
  width: 100%;
  overflow: auto;
}

.defaultHeader {
  display: flow-root;

}

.defaultHeader h3 {
  flex: 1;
  background-color: #004136;
  color: #e1e6e6;
  font-size: 1.1rem;
  min-height: 40px;
  display: flow-root;
  justify-content: flex-start;
  align-items: center;
  text-indent: 20px;
  box-shadow: 0.1rem 0.2rem 1rem 0.2rem rgba(0, 0, 0, 0.5);    
  border-radius: 5px;
  overflow: auto;

}

.defaultFooter h3 {
  flex: 1;
  background-color: #004136;
  color: #e1e6e6;
  font-size: 1rem;
  min-height: 40px;
  display: grid;
  justify-content: flex-start;
  align-items: flex-start;
  text-indent: 20px;
  box-shadow: 0.1rem 0.2rem 1rem 0.2rem rgba(0, 0, 0, 0.5);    
  border-radius: 5px;
  overflow: auto;
  /* margin-top: 10px; */

}

.defaultHeader h3 strong {
  text-indent: 3px;
}

/* iframe:first-child{
  pointer-events: none;
} */