.Navbar {
    display: flex;
    width: 230px;
    height: 200vh;
    background-color: #e1e6e6;
    align-items: flex-start;
    flex-direction: column;
    border-right: 2px solid rgb(225, 230, 230);
    padding-left: 1px;
}

.Navbar-hide {
    display: flex;
    width: 55px;
    height: 100vh;
    background-color: #e1e6e6;
    align-items: flex-start;
    flex-direction: column;
    border-right: 1px solid rgb(225, 230, 230);
    padding-left: 1px;
}

.fr_icon_navbar {
    /* width: 50px; */
}

.fr_logo_navbar {
    display: flex;
    flex: 1;
    width: 200px;
    vertical-align: center;
}

a.atalho.nav-link {
    flex: 1;
    color: black;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    vertical-align: center;
}

a.nav-link:hover {
    color: black;
   
}

a.nav-link.active:hover {
    color: black;

}

.toggle_navbar {
    background-color: transparent;
    border: none;
    color: #004136;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
}


.toggleMenuButton:hover {
    color: black;


}


.hide-toggleMenuButton:hover {
    color: black;


}


.toggleMenuButton {
    background-color: transparent;
    border: none;
    color: #004136;
    display: flex;
    width: 100%;
    align-items: center;
    padding-left: 15px;
    justify-content: left;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    margin-top: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.hide-toggleMenuButton {
    background-color: transparent;
    border: none;
    color: #004136;
    display: flex;
    width: 100%;
    align-items: center;
    padding-left: 0px;
    justify-content: center;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.Open {
    background-color: rgb(0, 65, 54);

}

.Open:hover {
    color: black;

}


.toggleMenu {
    border: solid 2px rgb(225, 230, 230);
    border-top: none;
}

.toggleMenuItem {
    flex: 1;
    color: #004136;
    display: flex;
    align-content: center;
    justify-content: left;
    align-items: center;
    padding-left: 25px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    margin-top: 20px;

}

.hide-toggleMenuItem {
    flex: 1;
    color: #004136;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    margin-top: 20px;

}

