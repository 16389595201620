.Manut {
    display: flex;
    background-color:#e1e6e6;
}

.ManutContent {
    padding: 50px;
    padding-bottom: 15px;
    background-color:#e1e6e6;    
    flex: 1;
    height: 100%;
    max-height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.button {
    font: bold 11px Arial;
    text-decoration: none;
    background-color: #004136;
    color: #e1e6e6;
    padding: 2px 6px 2px 6px;
    /* border-top: 1px solid #CCCCCC;
    border-right: 1px solid #333333;
    border-bottom: 1px solid #333333;
    border-left: 1px solid #CCCCCC; */
  }