
.container{
    display:flex;
    align-items: flex-start;
    flex-wrap: wrap; 
    height: 500px;
    width: 700px;
    padding-block: 20px;
    overflow: auto;
}

#disabled {
    color: rgba(0, 0, 0, 0.9);
    background-color: #ead6d6;
    cursor: not-allowed;
}


