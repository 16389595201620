.filtersDiv {
    border-bottom: 2px solid white;
    padding-bottom: 15px;
}

.filtersDiv form {
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
}

.tableDiv {
    padding-top: 30px;
    padding-bottom: 30px;
}

.filtersDiv form button:not(.MuiButtonBase-root) {
    border: #004136;
    border-radius: 5px;
    width: 160px;
    background-color: #004136;
    color: white;           
    height: 30px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    margin-right: 6px;
    margin-bottom: 10px;
}

.filtersDiv form button:hover:not(.MuiButtonBase-root) {
    color: white;
}

.filtersDiv form button:active:not(.MuiButtonBase-root) {
    transform: translateY(4px);
}

.tableDiv {
    padding-bottom: 0;
}

