.footerDelphus {
    vertical-align: bottom;
    align-self: flex-end;
    flex: 1;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-size: 0.7rem;
    margin-top: 20px;
  }