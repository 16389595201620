@import url(https://fonts.googleapis.com/css2?family=Mitr&family=Saira&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Login {
    flex: 1 1;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Login::after {
    content: "";
    /* background-image: url('../../imgs/wallpaper.jpg'); */
    background-color: #e1e6e6;
    opacity: 0.8;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;   
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}


.Login .logo {
    top: 5vh;
    height: 10vh;
    position: absolute;
    float: left;
    left: 50%;
    transform: translate(-50%, 0%);
    max-width: 80vw;
}


.Login p {
    margin-top: -15px;
    margin-bottom: 40px;
}


.Login .form {
    background-color:#e1e6e6;
    min-height: 200px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0.1rem 0.2rem 1rem 0.2rem rgba(0, 0, 0, 0.5);    
}

.headerlogin {
    margin-bottom: 2rem;
    font-weight: bolder;
    font-size: 2rem;
    color: #004136;
  }

  .headerforgot {
    margin-bottom: 2rem;
    font-weight: bolder;
    font-size: 1rem;
    color: #004136;
  }

  .footerlogin {
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: 0.9rem;
    color: #004136;
  }

.form-label-group input::placeholder {
    color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
    padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
    padding-bottom: calc(var(--input-padding-y));
}

.form-label-group input:not(:placeholder-shown)~label {
    padding-top: calc(var(--input-padding-y) / 2);
    padding-bottom: calc(var(--input-padding-y) / 3);
    font-size: 12px;
    color: #000000;
}

:root {
    --input-padding-x: 1.2rem;
    --input-padding-y: 0.9rem;
}

.form-label-group>label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0;
    line-height: 0;
    color: black;
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: all .1s ease-in-out;
    margin-top: 5px;
}

.form-label-group>input,
.form-label-group>label {
    padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group {
    position: relative;
    margin-bottom: 10px;
}

.loginButtonText {
    display: inline;
    /* margin-left: 10px; */
}
  
.loginButton span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.4s;
}

.loginButton span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}


.loginButton:hover{
    background-color: #004136;
}

.loginButton:hover span {
    padding-right: 25px;
}

.loginButton:hover span:after {
    opacity: 1;
    right: 0;
}

.loginText {
    margin-left: 30px;
    margin-right: 30px;
    font-size: 1.1em;
}
  
.loginInput {
    font-size: 1.1em;
    height: 40px;
    border: 2px solid #004136;
    border-radius: 3px;
    border-color:#004136;
}


.loginButton {
	background-color:#004136;
	margin-top: 15px;
	border-radius: 3px;
	vertical-align: center;
	text-align: center;
	width: 100%;
    height: 30px;
    min-height: 40px;
    border: 3px solid 004136;
    padding: 3px;
    color: white;
    transition: all 0.4s;
    cursor: pointer;
    margin-bottom: 15px;
}


.Navbar {
    display: flex;
    width: 230px;
    height: 200vh;
    background-color: #e1e6e6;
    align-items: flex-start;
    flex-direction: column;
    border-right: 2px solid rgb(225, 230, 230);
    padding-left: 1px;
}

.Navbar-hide {
    display: flex;
    width: 55px;
    height: 100vh;
    background-color: #e1e6e6;
    align-items: flex-start;
    flex-direction: column;
    border-right: 1px solid rgb(225, 230, 230);
    padding-left: 1px;
}

.fr_icon_navbar {
    /* width: 50px; */
}

.fr_logo_navbar {
    display: flex;
    flex: 1 1;
    width: 200px;
    vertical-align: center;
}

a.atalho.nav-link {
    flex: 1 1;
    color: black;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    vertical-align: center;
}

a.nav-link:hover {
    color: black;
   
}

a.nav-link.active:hover {
    color: black;

}

.toggle_navbar {
    background-color: transparent;
    border: none;
    color: #004136;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px; /* Safari */
    transition-duration: 0.4s;
}


.toggleMenuButton:hover {
    color: black;


}


.hide-toggleMenuButton:hover {
    color: black;


}


.toggleMenuButton {
    background-color: transparent;
    border: none;
    color: #004136;
    display: flex;
    width: 100%;
    align-items: center;
    padding-left: 15px;
    justify-content: left; /* Safari */
    transition-duration: 0.4s;
    margin-top: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.hide-toggleMenuButton {
    background-color: transparent;
    border: none;
    color: #004136;
    display: flex;
    width: 100%;
    align-items: center;
    padding-left: 0px;
    justify-content: center; /* Safari */
    transition-duration: 0.4s;
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.Open {
    background-color: rgb(0, 65, 54);

}

.Open:hover {
    color: black;

}


.toggleMenu {
    border: solid 2px rgb(225, 230, 230);
    border-top: none;
}

.toggleMenuItem {
    flex: 1 1;
    color: #004136;
    display: flex;
    align-content: center;
    justify-content: left;
    align-items: center;
    padding-left: 25px; /* Safari */
    transition-duration: 0.4s;
    margin-top: 20px;

}

.hide-toggleMenuItem {
    flex: 1 1;
    color: #004136;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center; /* Safari */
    transition-duration: 0.4s;
    margin-top: 20px;

}


.userOptions {
    right: 40px;
    background-color:#004136;
    border-top: none;
    padding-left: 6px;
    padding-top: 8px;
    height: 40px;
    z-index: 33;
    /* width: 250px; */
    width: max-content;
    
    color: #e1e6e6;
    box-shadow: 0.1rem 0.2rem 1rem 0.2rem rgba(0, 0, 0, 0.5);    
    border-radius: 5px;
    overflow: auto;
    
}

.userName {
    display: inline;

}

.userOptions button {
    margin-left: 15px;
    margin-right: 4px;
    border: none;
    background-color: transparent;
    color: #e1e6e6;
    float: right;
}

.userOptions button:hover {
    border-radius: 2px;
    border: 1px solid  #e1e6e6;
    color: #e1e6e6;
    margin-left: 19px;
    margin-right: 4px;
    margin-top: -1px;
}
.Home {
    display: flex;
}

.HomeContent {
    padding: 50px;
    padding-bottom: 15px;
    background-color:#e1e6e6;
    flex: 1 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 100vh;
    
}

.HomeContent a.nav-link {
    background-color: #004136;
    display: flex;
    flex-direction: column;
    width: 150px;
    height: 150px;
    color: #e1e6e6;
    box-shadow: 0.1rem 0.2rem 1rem 0.2rem rgba(0, 0, 0, 0.5);    
    border-radius: 5px;
  
}


.HomeOptions {
    margin-bottom: 30px;
    margin-top: 30px;
}

.atalhos {
    display: flex;
    justify-content: flex-start;
    
}

.atalho {
    margin-right: 30px;
    max-width: 200px;   
    text-align: center;
}


@media (max-width : 460px){

    .atalhos {
        display: flow-root;
        justify-content: center;
    }
    

    .HomeContent {
        padding: 50px;
        padding-bottom: 15px;
        background-color:#e1e6e6;
        flex: 1 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        max-height: 100vh;
        
    }
    
    .HomeContent a.nav-link {
        margin-top: 15px;
        background-color: #004136;
        display: flex;
        flex-direction: column;
        width: 250px;
        height: 150px;
        color: #e1e6e6;
        box-shadow: 0.1rem 0.2rem 1rem 0.2rem rgba(0, 0, 0, 0.5);    
        border-radius: 5px;
        align-items: center;
      
    }
       
}

.botaoDesconto {
    margin-top: 15px;
    background-color: #004136;
    display: flex;
    flex-direction: column;
    width: 220px;
    height: 100px;
    color: #e1e6e6;
    box-shadow: 0.1rem 0.2rem 1rem 0.2rem rgba(0, 0, 0, 0.5);    
    border-radius: 5px;
    align-items: center;
    justify-content: center;
}

.botaoDesconto:hover{
    color: black;
}
.footerDelphus {
    vertical-align: bottom;
    align-self: flex-end;
    flex: 1 1;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-size: 0.7rem;
    margin-top: 20px;
  }
.FrLoading {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.defaultReg {
    display: flex;
    background-color: #e1e6e6;
}

.defaultRegContent {
    padding: 50px;
    padding-bottom: 15px;
    background-color: #e1e6e6;
    flex: 1 1;
    height: 100%;
    overflow-y: auto;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
}

.defaultHeaderReg {
    display: flex;
  
  }
  
  .defaultHeaderReg h3 {
    flex: 1 1;
    background-color: #004136;
    color: #e1e6e6;
    font-size: 1rem;
    min-height: 40px;
    display: flow-root;
    justify-content: center;
    align-items: center;
    text-indent: 10px;
    box-shadow: 0.1rem 0.2rem 1rem 0.2rem rgba(0, 0, 0, 0.5);    
    border-radius: 5px;
    overflow: auto;
    padding-top: 12px;
  
  }
  
.tableDiv {
    padding-top: 0;
}

#searchForm button:not(.MuiButtonBase-root) {
    margin-bottom: 0;
}

#searchForm i {
    padding-right: 5px;
    /* padding-top: 2px; */
}

.regButtonsDiv {
    display: flex;
    align-items: flex-end;
    align-content: flex-end;
    justify-content: flex-end;

}

.regButtons {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;

}

.regButtons button:not(.MuiButtonBase-root) {
    width: 130px;
    border: '2px solid #004136';
    border-radius: 4;
    background-color:#004136;
    color: white;  
    height: 30px; /* Safari */
    transition-duration: 0.4s;
    margin-top: 10px;
    margin-left: 10px;
}

.buttonRegras {
    width: 200px;
    border: '2px solid #004136';
    border-radius: 4;
    background-color:#004136;
    color: white;  
    height: 30px; /* Safari */
    transition-duration: 0.4s;
    margin-top: 10px;
    margin-left: 10px;
}

.regButtonsDiv button:not(.MuiButtonBase-root) {
    width: 80px;
    border: '2px solid #004136';
    border-radius: 4;
    background-color:#004136;
    color: white;  
    height: 30px; /* Safari */
    transition-duration: 0.4s;
    margin-top: 10px;
    margin-left: 10px;
}

.regButtonsDiv button:hover:not(.MuiButtonBase-root) {
    color: black;
}

.regButtonsDiv button:active:not(.MuiButtonBase-root) {
    transform: translateY(4px);
}

.defaultRegContent form {
    padding-bottom: 40px;
    border-bottom: 2px solid #004136;

}

.selectFileButton {
    border: '2px solid #004136';
    border-radius: 5;
    width: 200px;
    background-color:#004136;
    color: white;
    height: 30px; /* Safari */
    transition-duration: 0.4s;
    margin-right: 5px;
}

.editButton {
    border: '2px solid #004136';
    border-radius: 5;
    width: 100px;
    background-color:#004136;
    color: white;
    height: 30px; /* Safari */
    transition-duration: 0.4s;
    margin-right: 5px;
}

.regButton {
    border: '2px solid #004136';
    border-radius: 5;
    width: 130px;
    background-color:#004136;
    color: white;
    height: 30px; /* Safari */
    transition-duration: 0.4s;
    margin-right: 5px;
}

#html_btn {
	display: none;
}

.Servicos {
    display: flex;
    margin-top: 10px;
    margin-right: 30px;
    width: 300px;

}

.regButtonsTab {
    display: flex;
    align-items: flex-end;
    align-content: flex-end;
    justify-content: flex-end;
}

.regButtonsTab button:not(.MuiButtonBase-root) {
    border: '1px solid #004136';
    border-radius: 5;
    width: 130px;
    background-color:#004136;
    color: white;   
    height: 30px; /* Safari */
    transition-duration: 0.4s;
    margin-top: 10px;
    margin-left: 10px;
}


.ModalShow {
	display: block;
}

.ModalHide {
	display: none;
}



.Modal {
	background-color: rgba(0, 0, 0, 0.5);
	color: rgba(0, 0, 0, 0.5);
	overflow-y: auto;
}


.ModalBg {
	background: #e1e6e6;
    color:  black;   
    border: 1px solid #004136;

}

.ModalBody {
	z-index: 1500;
	margin-left: 10%;
	margin-bottom: 10px;
	width: 90%;
	height: auto;
	text-align: left;
}

.ModalHeader {
	z-index: 1500;
	margin-left: 10%;
	margin-top: 10px;
	width: 90%;
	height: auto;
	text-align: center;
}


.labelModal {
	display: flex;
	font-size: .9em;
	color: black;
	text-align: center;
	margin-top: 10px;
}


.divBorder {
    border: 3px solid #004136;
    border-radius: 4px;
    box-shadow: 0.1rem 0.2rem 1rem 0.2rem rgba(0, 0, 0, 0.5);    
    background-color: #e1e6e6;
    padding: 50px;
    overflow-y: auto;
}


@media (max-width : 460px){

.defaultHeaderReg {
    display: flow-root;
  }

  .defaultHeaderReg h3 {
    width: 100%; 
    margin-top: 5px;
    flex: 1 1;
    background-color: #004136;
    color: #e1e6e6;
    font-size: 1rem;
    min-height: 40px;
    display: flow-root;
    justify-content: center;
    align-items: center;
    text-indent: 10px;
    box-shadow: 0.1rem 0.2rem 1rem 0.2rem rgba(0, 0, 0, 0.5);    
    border-radius: 5px;
    overflow: auto;
    padding-top: 12px;
  }

  .defaultRegContent {
    padding: 10px;
    padding-bottom: 15px;
    background-color: #e1e6e6;
    flex: 1 1;
    height: 100%;
    overflow-y: auto;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;

}

.ModalShow {
	display: flex;
}

}




.tableEdit, .tableDelete {
    padding: 3px;
    margin-right: 5px; /* Safari */
    transition-duration: 0s;
    color: black
}


.tableEdit:hover, .tableDelete:hover {
    padding: 2px;
    border-radius: 2px;
    border: 1px solid rgba(73, 87, 59, 0.733);
    color: #49573B;
}

.tableEdit:active, .tableDelete:active { /* Safari */
    transition-duration: 0.4s;
    transform: translateY(4px);
}
.filtersDiv {
    border-bottom: 2px solid white;
    padding-bottom: 15px;
}

.filtersDiv form {
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
}

.tableDiv {
    padding-top: 30px;
    padding-bottom: 30px;
}

.filtersDiv form button:not(.MuiButtonBase-root) {
    border: #004136;
    border-radius: 5px;
    width: 160px;
    background-color: #004136;
    color: white;           
    height: 30px; /* Safari */
    transition-duration: 0.4s;
    margin-right: 6px;
    margin-bottom: 10px;
}

.filtersDiv form button:hover:not(.MuiButtonBase-root) {
    color: white;
}

.filtersDiv form button:active:not(.MuiButtonBase-root) {
    transform: translateY(4px);
}

.tableDiv {
    padding-bottom: 0;
}


.Manut {
    display: flex;
    background-color:#e1e6e6;
}

.ManutContent {
    padding: 50px;
    padding-bottom: 15px;
    background-color:#e1e6e6;    
    flex: 1 1;
    height: 100%;
    max-height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.button {
    font: bold 11px Arial;
    text-decoration: none;
    background-color: #004136;
    color: #e1e6e6;
    padding: 2px 6px 2px 6px;
    /* border-top: 1px solid #CCCCCC;
    border-right: 1px solid #333333;
    border-bottom: 1px solid #333333;
    border-left: 1px solid #CCCCCC; */
  }
.tableEdit, .tableDelete {
    padding: 3px;
    margin-right: 5px; /* Safari */
    transition-duration: 0s;
    color: black;
    border-radius: 2;
}


.tableEdit:hover, .tableDelete:hover {
    padding: 2px;
    border-radius: 2px;
    border: 2px solid rgba(0, 65, 54);
    border-radius: 2;
    color:'#004136'


}

.tableEdit:active, .tableDelete:active { /* Safari */
    transition-duration: 0.4s;
    transform: translateY(4px);
    border-radius: 2;
    color:'#004136'

}


.FrModalFooter {
    background-color: #F7EFEF;
  }
  
  .FrModalButtonsDiv {
    display: flex;
    align-items: flex-end;
    align-content: flex-end;
    justify-content: flex-end;
    background-color: #F7EFEF;
    margin-right: 20px;
    margin-left: 20px;
    padding-bottom: 20px;
  }
  
  .FrModalButtonsDiv button:not(.MuiButtonBase-root) {
    border: none;
    width: 100px;
    /* background-color: #9c9595; */
    background-color: rgba(0, 65, 54);
    color: white;
    /* color: black; */
    
    height: 30px; /* Safari */
    transition-duration: 0.4s;
    margin-top: 10px;
    margin-left: 10px;
  }
  
  .FrModalButtonsDiv button:hover:not(.MuiButtonBase-root) {
    color: #000;
  }
  
  .FrModalButtonsDiv button:active:not(.MuiButtonBase-root) {
    transform: translateY(4px);
  }
  

.container{
    display:flex;
    align-items: flex-start;
    flex-wrap: wrap; 
    height: 500px;
    width: 700px;
    padding-block: 20px;
    overflow: auto;
}

#disabled {
    color: rgba(0, 0, 0, 0.9);
    background-color: #ead6d6;
    cursor: not-allowed;
}



*, html {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-family: 'Saira', sans-serif;
}

h1, h2, h3, h4 {
  font-family: 'Saira', sans-serif;
}


html {
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  font-size: 0.8rem;
}

.App {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  align-content: stretch;
  height: 100vh;
  width: 100%;
  overflow: auto;
}

.defaultHeader {
  display: flow-root;

}

.defaultHeader h3 {
  flex: 1 1;
  background-color: #004136;
  color: #e1e6e6;
  font-size: 1.1rem;
  min-height: 40px;
  display: flow-root;
  justify-content: flex-start;
  align-items: center;
  text-indent: 20px;
  box-shadow: 0.1rem 0.2rem 1rem 0.2rem rgba(0, 0, 0, 0.5);    
  border-radius: 5px;
  overflow: auto;

}

.defaultFooter h3 {
  flex: 1 1;
  background-color: #004136;
  color: #e1e6e6;
  font-size: 1rem;
  min-height: 40px;
  display: grid;
  justify-content: flex-start;
  align-items: flex-start;
  text-indent: 20px;
  box-shadow: 0.1rem 0.2rem 1rem 0.2rem rgba(0, 0, 0, 0.5);    
  border-radius: 5px;
  overflow: auto;
  /* margin-top: 10px; */

}

.defaultHeader h3 strong {
  text-indent: 3px;
}

/* iframe:first-child{
  pointer-events: none;
} */
