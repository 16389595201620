.Home {
    display: flex;
}

.HomeContent {
    padding: 50px;
    padding-bottom: 15px;
    background-color:#e1e6e6;
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 100vh;
    
}

.HomeContent a.nav-link {
    background-color: #004136;
    display: flex;
    flex-direction: column;
    width: 150px;
    height: 150px;
    color: #e1e6e6;
    box-shadow: 0.1rem 0.2rem 1rem 0.2rem rgba(0, 0, 0, 0.5);    
    border-radius: 5px;
  
}


.HomeOptions {
    margin-bottom: 30px;
    margin-top: 30px;
}

.atalhos {
    display: flex;
    justify-content: flex-start;
    
}

.atalho {
    margin-right: 30px;
    max-width: 200px;   
    text-align: center;
}


@media (max-width : 460px){

    .atalhos {
        display: flow-root;
        justify-content: center;
    }
    

    .HomeContent {
        padding: 50px;
        padding-bottom: 15px;
        background-color:#e1e6e6;
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        max-height: 100vh;
        
    }
    
    .HomeContent a.nav-link {
        margin-top: 15px;
        background-color: #004136;
        display: flex;
        flex-direction: column;
        width: 250px;
        height: 150px;
        color: #e1e6e6;
        box-shadow: 0.1rem 0.2rem 1rem 0.2rem rgba(0, 0, 0, 0.5);    
        border-radius: 5px;
        align-items: center;
      
    }
       
}

.botaoDesconto {
    margin-top: 15px;
    background-color: #004136;
    display: flex;
    flex-direction: column;
    width: 220px;
    height: 100px;
    color: #e1e6e6;
    box-shadow: 0.1rem 0.2rem 1rem 0.2rem rgba(0, 0, 0, 0.5);    
    border-radius: 5px;
    align-items: center;
    justify-content: center;
}

.botaoDesconto:hover{
    color: black;
}