.userOptions {
    right: 40px;
    background-color:#004136;
    border-top: none;
    padding-left: 6px;
    padding-top: 8px;
    height: 40px;
    z-index: 33;
    /* width: 250px; */
    width: max-content;
    
    color: #e1e6e6;
    box-shadow: 0.1rem 0.2rem 1rem 0.2rem rgba(0, 0, 0, 0.5);    
    border-radius: 5px;
    overflow: auto;
    
}

.userName {
    display: inline;

}

.userOptions button {
    margin-left: 15px;
    margin-right: 4px;
    border: none;
    background-color: transparent;
    color: #e1e6e6;
    float: right;
}

.userOptions button:hover {
    border-radius: 2px;
    border: 1px solid  #e1e6e6;
    color: #e1e6e6;
    margin-left: 19px;
    margin-right: 4px;
    margin-top: -1px;
}