.defaultReg {
    display: flex;
    background-color: #e1e6e6;
}

.defaultRegContent {
    padding: 50px;
    padding-bottom: 15px;
    background-color: #e1e6e6;
    flex: 1;
    height: 100%;
    overflow-y: auto;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
}

.defaultHeaderReg {
    display: flex;
  
  }
  
  .defaultHeaderReg h3 {
    flex: 1;
    background-color: #004136;
    color: #e1e6e6;
    font-size: 1rem;
    min-height: 40px;
    display: flow-root;
    justify-content: center;
    align-items: center;
    text-indent: 10px;
    box-shadow: 0.1rem 0.2rem 1rem 0.2rem rgba(0, 0, 0, 0.5);    
    border-radius: 5px;
    overflow: auto;
    padding-top: 12px;
  
  }
  
.tableDiv {
    padding-top: 0;
}

#searchForm button:not(.MuiButtonBase-root) {
    margin-bottom: 0;
}

#searchForm i {
    padding-right: 5px;
    /* padding-top: 2px; */
}

.regButtonsDiv {
    display: flex;
    align-items: flex-end;
    align-content: flex-end;
    justify-content: flex-end;

}

.regButtons {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;

}

.regButtons button:not(.MuiButtonBase-root) {
    width: 130px;
    border: '2px solid #004136';
    border-radius: 4;
    background-color:#004136;
    color: white;  
    height: 30px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    margin-top: 10px;
    margin-left: 10px;
}

.buttonRegras {
    width: 200px;
    border: '2px solid #004136';
    border-radius: 4;
    background-color:#004136;
    color: white;  
    height: 30px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    margin-top: 10px;
    margin-left: 10px;
}

.regButtonsDiv button:not(.MuiButtonBase-root) {
    width: 80px;
    border: '2px solid #004136';
    border-radius: 4;
    background-color:#004136;
    color: white;  
    height: 30px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    margin-top: 10px;
    margin-left: 10px;
}

.regButtonsDiv button:hover:not(.MuiButtonBase-root) {
    color: black;
}

.regButtonsDiv button:active:not(.MuiButtonBase-root) {
    transform: translateY(4px);
}

.defaultRegContent form {
    padding-bottom: 40px;
    border-bottom: 2px solid #004136;

}

.selectFileButton {
    border: '2px solid #004136';
    border-radius: 5;
    width: 200px;
    background-color:#004136;
    color: white;
    height: 30px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    margin-right: 5px;
}

.editButton {
    border: '2px solid #004136';
    border-radius: 5;
    width: 100px;
    background-color:#004136;
    color: white;
    height: 30px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    margin-right: 5px;
}

.regButton {
    border: '2px solid #004136';
    border-radius: 5;
    width: 130px;
    background-color:#004136;
    color: white;
    height: 30px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    margin-right: 5px;
}

#html_btn {
	display: none;
}

.Servicos {
    display: flex;
    margin-top: 10px;
    margin-right: 30px;
    width: 300px;

}

.regButtonsTab {
    display: flex;
    align-items: flex-end;
    align-content: flex-end;
    justify-content: flex-end;
}

.regButtonsTab button:not(.MuiButtonBase-root) {
    border: '1px solid #004136';
    border-radius: 5;
    width: 130px;
    background-color:#004136;
    color: white;   
    height: 30px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    margin-top: 10px;
    margin-left: 10px;
}


.ModalShow {
	display: block;
}

.ModalHide {
	display: none;
}



.Modal {
	background-color: rgba(0, 0, 0, 0.5);
	color: rgba(0, 0, 0, 0.5);
	overflow-y: auto;
}


.ModalBg {
	background: #e1e6e6;
    color:  black;   
    border: 1px solid #004136;

}

.ModalBody {
	z-index: 1500;
	margin-left: 10%;
	margin-bottom: 10px;
	width: 90%;
	height: auto;
	text-align: left;
}

.ModalHeader {
	z-index: 1500;
	margin-left: 10%;
	margin-top: 10px;
	width: 90%;
	height: auto;
	text-align: center;
}


.labelModal {
	display: flex;
	font-size: .9em;
	color: black;
	text-align: center;
	margin-top: 10px;
}


.divBorder {
    border: 3px solid #004136;
    border-radius: 4px;
    box-shadow: 0.1rem 0.2rem 1rem 0.2rem rgba(0, 0, 0, 0.5);    
    background-color: #e1e6e6;
    padding: 50px;
    overflow-y: auto;
}


@media (max-width : 460px){

.defaultHeaderReg {
    display: flow-root;
  }

  .defaultHeaderReg h3 {
    width: 100%; 
    margin-top: 5px;
    flex: 1;
    background-color: #004136;
    color: #e1e6e6;
    font-size: 1rem;
    min-height: 40px;
    display: flow-root;
    justify-content: center;
    align-items: center;
    text-indent: 10px;
    box-shadow: 0.1rem 0.2rem 1rem 0.2rem rgba(0, 0, 0, 0.5);    
    border-radius: 5px;
    overflow: auto;
    padding-top: 12px;
  }

  .defaultRegContent {
    padding: 10px;
    padding-bottom: 15px;
    background-color: #e1e6e6;
    flex: 1;
    height: 100%;
    overflow-y: auto;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;

}

.ModalShow {
	display: flex;
}

}



