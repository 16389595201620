.tableEdit, .tableDelete {
    padding: 3px;
    margin-right: 5px;
    -webkit-transition-duration: 0s; /* Safari */
    transition-duration: 0s;
    color: black;
    border-radius: 2;
}


.tableEdit:hover, .tableDelete:hover {
    padding: 2px;
    border-radius: 2px;
    border: 2px solid rgba(0, 65, 54);
    border-radius: 2;
    color:'#004136'


}

.tableEdit:active, .tableDelete:active {
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    transform: translateY(4px);
    border-radius: 2;
    color:'#004136'

}


.FrModalFooter {
    background-color: #F7EFEF;
  }
  
  .FrModalButtonsDiv {
    display: flex;
    align-items: flex-end;
    align-content: flex-end;
    justify-content: flex-end;
    background-color: #F7EFEF;
    margin-right: 20px;
    margin-left: 20px;
    padding-bottom: 20px;
  }
  
  .FrModalButtonsDiv button:not(.MuiButtonBase-root) {
    border: none;
    width: 100px;
    /* background-color: #9c9595; */
    background-color: rgba(0, 65, 54);
    color: white;
    /* color: black; */
    
    height: 30px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    margin-top: 10px;
    margin-left: 10px;
  }
  
  .FrModalButtonsDiv button:hover:not(.MuiButtonBase-root) {
    color: #000;
  }
  
  .FrModalButtonsDiv button:active:not(.MuiButtonBase-root) {
    transform: translateY(4px);
  }
  